.root {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.45;
  color: var(--palette-text-900);
  word-wrap: break-word;
}
.root b,
  .root strong {
    font-weight: var(--font-weight-primary-bold);
  }
.root i,
  .root em {
    font-style: italic;
  }
.root mark {
    background-color: var(--palette-warning-500);
    padding: 0 2px;
  }
.root mark a {
      color: #2C7B8C;
    }
.root a {
    color: #2C7B8C;
    background-color: var(--palette-warning-500);
    padding: 0 2px;
  }
.root a:hover {
      color: #11435D;
    }
.highlight {
  white-space: pre-wrap;
}
